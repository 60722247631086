import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChunkErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    if (error instanceof Error && error.message.includes('ChunkLoadError')) {
      window.location.reload();
    } else {
      console.error('Erro capturado pelo GlobalErrorHandler:', error);
    }
  }
}
