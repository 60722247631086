import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { catchError, from, Observable, switchMap, throwError } from 'rxjs';
import { AuthService } from 'src/app/core/common/services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  private authService: AuthService = inject(AuthService);

  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(Auth.currentSession()).pipe(
      switchMap((auth: any) => {
        let jwt = auth.accessToken.jwtToken;
        let with_auth_request = req.clone({
          setHeaders: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        return next.handle(with_auth_request).pipe(
          catchError((error) => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              // Token expirado ou inválido, tentar renovar
              return from(Auth.currentSession()).pipe(
                switchMap((auth: any) => {
                  let new_jwt = auth.accessToken.jwtToken;
                  let new_auth_request = req.clone({
                    setHeaders: {
                      Authorization: `Bearer ${new_jwt}`,
                    },
                  });

                  return next.handle(new_auth_request);
                }),
                catchError((refreshError) => {
                  return throwError(() => refreshError);
                })
              );
            } else {
              return throwError(() => error);
            }
          })
        );
      })
    );
  }
}
