import { createAction, props } from '@ngrx/store';

export const setMenuPermissions = createAction(
  '[Menu] Set Menu Permissions',
  props<{ permissions: any[] }>()
);

export const setMenuFavorites = createAction(
  '[Menu] Set Menu Favorites',
  props<{ favorites: any[] }>()
);

export const setUsername = createAction(
  '[Menu] Set Username',
  props<{ username: string }>()
);

export const setOthersAccess = createAction(
  '[Menu] Set Others Access',
  props<{ othersAccess: any[] }>()
);

export const setWelcomeTuor = createAction(
  '[Menu] Set Welcome Tour',
  props<{ showWelcomeTour: boolean }>()
);

export const addFavoriteItem = createAction(
  '[Menu] Add Favorite Item',
  props<{ item: string }>()
);

export const removeFavoriteItem = createAction(
  '[Menu] Remove Favorite Item',
  props<{ item: string }>()
);

export const updateShowWelcomeTour = createAction(
  '[Menu] Update Show Welcome Tour',
  props<{ showWelcomeTour: boolean }>()
);

export const clearMenu = createAction('[Menu] Clear store menu');

export const setWritePermissions = createAction(
  '[Menu] Set Write Permissions',
  props<{ onlyWritePermissions: any[] }>()
);
