import { Component, inject, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-app-modal-update-propulsor',
  standalone: true,
  imports: [DialogModule, ButtonModule, TooltipModule, TranslateModule],
  templateUrl: './app-modal-update-propulsor.component.html',
  styleUrls: ['./app-modal-update-propulsor.component.scss'],
})
export class AppModalUpdatePropulsorComponent implements OnInit {
  version: string = '';
  private translate: TranslateService = inject(TranslateService);

  private ref: DynamicDialogRef = inject(DynamicDialogRef);
  private config: DynamicDialogConfig = inject(DynamicDialogConfig);

  constructor() {}

  ngOnInit() {
    this.version = this.config.data.version;
  }

  reloadThisPage() {
    window.location.reload();
    this.ref.close();
  }
}
