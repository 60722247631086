import { CommonModule } from '@angular/common';
import { NgModule, SecurityContext } from '@angular/core';
import { MarkdownModule, MarkdownService, MarkedOptions } from 'ngx-markdown';
import { Erro404Component } from './common/components/erro404/erro404.component';

@NgModule({
  imports: [
    CommonModule,
    MarkdownModule.forRoot({
      sanitize: SecurityContext.NONE,
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          breaks: true,
        },
      },
    }),
    MarkdownModule.forChild(),
    Erro404Component,
  ],
  exports: [Erro404Component],
  providers: [MarkdownService],
})
export class SharedModule {}
