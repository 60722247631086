import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ProfileFacade } from 'src/app/pages/settings/general-settings/management-registers/my-profile/facade/ProfileFacade';
import { NotificationService } from '../notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class WritePermissionGuard {
  private profileFacade: ProfileFacade = inject(ProfileFacade);
  private router: Router = inject(Router);
  private translate: TranslateService = inject(TranslateService);
  private notificationService: NotificationService =
    inject(NotificationService);

  constructor() {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const menu = route.data['code'];

    return new Observable<boolean>((subscriber) => {
      this.profileFacade
        .hasPermission(menu, 'WRITE')
        .then((hasPermission) => {
          if (hasPermission) {
            subscriber.next(true);
          } else {
            this.router.navigate(['/']);
            setTimeout(() => {
              this.notificationService.addMessageToService({
                severity: 'error',
                summary: this.translate.instant('messages.accessDeniedTitle'),
                detail: this.translate.instant('messages.accessDenied'),
              });
            }, 500);
            subscriber.next(false);
          }
          // subscriber.next(true);
        })
        .catch((error) => {
          subscriber.next(false);
        });
    });
  }
}
