<div class="dialog-section">
  <div class="dialog-section__confirmation mt-3">
    <div class="dialog-section__confirmation__text icon-text">
      <i class="pi pi-sync dialog-icon-style severity__info"></i>

      <div class="description">
        <p class="p-default-bold">
          {{ "messages.propulsorHasBeenUpgradedTo" | translate }} {{ version }}
        </p>

        <p class="p-default text-secondary text-left">
          {{
            "messages.toContinueUsingPlatformTakeAdvantageImprovementsCorrectionsRefreshPage"
              | translate
          }}
        </p>
      </div>
    </div>
  </div>

  <div class="dialog-section__footer">
    <div class="dialog-section__footer__left"></div>
    <div class="dialog-section__footer__right">
      <button
        pButton
        type="button"
        icon="pi pi-refresh"
        iconPos="left"
        label="{{ 'common.refreshThePage' | translate }}"
        class="p-button-raised primary"
        (click)="reloadThisPage()"
      ></button>
    </div>
  </div>
</div>
