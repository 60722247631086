export enum SubmenuCode {
  INTERNATIONAL = 'INTERNATIONAL',
  NATIONAL = 'NATIONAL',
  EMITTER_REGISTRATION = 'EMITTER_REGISTRATION',
  GENERAL_SETTINGS = 'GENERAL_SETTINGS',
  PLAN_BILLING = 'PLAN_BILLING',
  MANAGEMENT_REGISTERS = 'MANAGEMENT_REGISTERS',
  INTEGRATIONS = 'INTEGRATIONS',
  GENERAL_REGISTERS = 'GENERAL_REGISTERS',
  EMISSOR_REGISTERS = 'EMISSOR_REGISTERS',
  FOLLOW_MIC_DTA = 'FOLLOW_MIC_DTA',
}

export const SubmenuCodeLabel = new Map<SubmenuCode, string>([
  [SubmenuCode.INTERNATIONAL, 'Internacional'],
  [SubmenuCode.NATIONAL, 'Nacional'],
  [SubmenuCode.EMITTER_REGISTRATION, 'Cadastros'],
  [SubmenuCode.GENERAL_SETTINGS, 'Configurações Gerais'],
  [SubmenuCode.PLAN_BILLING, 'Faturamento'],
  [SubmenuCode.MANAGEMENT_REGISTERS, 'Cadastos gerenciais'],
  [SubmenuCode.INTEGRATIONS, 'Integrações'],
  [SubmenuCode.GENERAL_REGISTERS, 'Cadastros Gerais'],
  [SubmenuCode.EMISSOR_REGISTERS, 'Emissor de Docs.'],
  [SubmenuCode.FOLLOW_MIC_DTA, 'Follow Up: MIC/DTA'],
]);
