import { Component, OnInit } from '@angular/core';
import { SkeletonModule } from 'primeng/skeleton';

/**
 * * Componente de load para navbar
 * @description É um skeleton de carregamento para o navbar.
 * @author Dariane Abich
 * @date 24/04/2024
 * @export
 * @class LoadNavbarComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-load-navbar',
    templateUrl: './load-navbar.component.html',
    styleUrls: ['./load-navbar.component.scss'],
    standalone: true,
    imports: [SkeletonModule],
})
export class LoadNavbarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
