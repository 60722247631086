export enum MenuCode {
  DOCUMENT_ISSUER = 'DOCUMENT_ISSUER',
  FOLLOW_UP_MIC = 'FOLLOW_UP_MIC',
  TMS = 'TMS',
  JOURNEY = 'JOURNEY',
  COOPERATIVE = 'COOPERATIVE',
  WMS = 'WMS',
  FINANCE = 'FINANCE',
  REGISTRATIONS = 'REGISTRATIONS',
  SETTINGS = 'SETTINGS',
}

export const MenuCodeLabel = new Map<MenuCode, string>([
  [MenuCode.DOCUMENT_ISSUER, 'Emissor de Documentos'],
  [MenuCode.FOLLOW_UP_MIC, 'Follow-UP'],
  [MenuCode.TMS, 'Gestor de Rastreio - TMS'],
  [MenuCode.JOURNEY, 'Jornada do Motorista'],
  [MenuCode.COOPERATIVE, 'Cooperativa de Transportes'],
  [MenuCode.WMS, 'Gestor de Armazéns - WMS'],
  [MenuCode.FINANCE, 'Gestor Financeiro'],
  [MenuCode.REGISTRATIONS, 'Cadastros'],
  [MenuCode.SETTINGS, 'Configurações'],
]);
