import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChangePasswordModel } from 'src/app/core/common/models/password/ChangePasswordModel';
import * as model from 'src/app/core/common/models/profile/Profile';
import { ProfileApiService } from 'src/app/core/common/services/profile/profile.api.service';
import { IMenuState } from 'src/app/core/common/store/menu.state';
import { ProfileModelMapper } from './ProfileModelMapper';

@Injectable({
  providedIn: 'root',
})
export class ProfileFacade {
  private api: ProfileApiService = inject(ProfileApiService);
  private store: Store<{ menu: IMenuState }> = inject(
    Store<{ menu: IMenuState }>
  );

  modelMapper = new ProfileModelMapper();

  private homePage = new BehaviorSubject<boolean>(false);
  isHome$ = this.homePage.asObservable();

  constructor() {}

  getProfile() {
    return this.api.getProfile();
  }

  updateProfile(data: Object): Observable<model.ProfileModel> {
    let profile = this.modelMapper.mapToPutModel(data);
    return this.api.editProfile(profile);
  }

  changePassword(data: Object): Observable<ChangePasswordModel> {
    let password = this.modelMapper.mapToPutPasswordModel(data);
    return this.api.editPassword(password);
  }

  saveFavorite(code: string) {
    return this.api.saveFavoriteMenu(code);
  }

  removeFavorite(code: string) {
    return this.api.deleteFavoriteMenu(code);
  }

  saveWelcomeTour() {
    return this.api.completeWelcomeTour();
  }

  getPermission() {
    return this.api.getPermission();
  }

  hasPermission(code: string, permission: string) {
    return this.api.hasPermission(code, permission);
  }

  getRefresh() {
    return this.api.getRefresh();
  }

  setRefresh(refresh: boolean) {
    this.api.setRefresh(refresh);
  }

  isHome() {
    this.homePage.next(true);
  }

  getHome(): Observable<boolean> {
    return this.homePage.asObservable();
  }

  pushCompany(data: any) {
    this.api.pushCompanyStore(data);
  }
}
