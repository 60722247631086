import { createAction, props } from '@ngrx/store';
import { CompaniesState } from '../models/company-reducer/CompaniesState';
import { ProfileCompanyState } from '../models/company-reducer/ProfileCompanyState';

export const addCompaniesDetails = createAction(
  '[Profile] Add Companies Details',
  props<{ profileCompany: ProfileCompanyState }>()
);

export const updateSelectedCompany = createAction(
  '[Profile] Update Selected Company',
  props<{ selectedCompany: CompaniesState }>()
);

export const addItemOnAllCompaniesAndOthersCompanies = createAction(
  '[Profile] Add item on All Companies and Others Companies',
  props<{ company: CompaniesState }>()
);

export const updateItemOnAllCompaniesAndOthersCompanies = createAction(
  '[Profile] Update Item on All Companies and Others Companies',
  props<{ company: CompaniesState }>()
);

export const removeComapaniesDetails = createAction(
  '[Profile] Remove Companies Details'
);

export const removeItemOnAllCompaniesAndOthersCompanies = createAction(
  '[Profile] Remove Item on All Companies and Other Companies',
  props<{ company: CompaniesState }>()
);
