<section id="app-component">
  <router-outlet *ngIf="!(authService.showMenu$ | async)"></router-outlet>

  <div class="app-container" *ngIf="authService.showMenu$ | async">
    <div class="app-container__nav step-2-navbar" id="div-nav">
      <app-navbar></app-navbar>
    </div>

    <div class="app-container__menu" id="div-menu">
      <app-menu id="step-1-submenu"></app-menu>
    </div>

    <div class="app-container__body" id="div-body">
      <div
        class="app-container__body__card"
        *ngIf="authService.showMenu$ | async"
      >
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <!-- Modal de início da tour -->
  <p-dialog
    [(visible)]="initialTour"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '900px' }"
  >
    <div class="dialog-section">
      <div class="dialog-section__content">
        <img src="assets/images/module-truck-large.png" alt="" />
        <h4 class="heading-4">{{ "common.welcomeMessage" | translate }}</h4>
        <p class="p-default">
          {{ "appComponent.initialTourPhrase1" | translate }}
        </p>
        <p class="p-default">
          {{ "appComponent.initialTourPhrase2" | translate }}
        </p>
      </div>

      <div class="dialog-section__footer">
        <div class="dialog-section__footer__left"></div>

        <div class="dialog-section__footer__right">
          <button
            pButton
            class="p-button-text secondary"
            label="{{ 'appComponent.skipStep' | translate }}"
            (click)="skipTourModal()"
          ></button>

          <button
            pButton
            class="p-button-raised primary"
            label="{{ 'appComponent.goToTutorial' | translate }}"
            icon="pi pi-arrow-right"
            iconPos="right"
            (click)="configShephard()"
          ></button>
        </div>
      </div>
    </div>
  </p-dialog>

  <!-- Modal de término da tour -->
  <p-dialog
    [(visible)]="finishTour"
    [modal]="true"
    [closable]="false"
    [style]="{ width: 'auto', height: 'auto' }"
  >
    <div class="dialog-section">
      <div class="conteudo-finish">
        <div class="cabecalho-finish">
          <h4 class="heading-4">{{ "appComponent.allReady" | translate }}</h4>
          <p class="p-default">
            {{ "appComponent.finishTourPhrase" | translate }}
          </p>
        </div>

        <div class="contacts-modal">
          <div class="card">
            <div class="card-content">
              <i class="pi pi-phone"></i>
              <p class="p-default">{{ dbsystemNumber }}</p>
            </div>
          </div>

          <div class="card">
            <div class="card-content">
              <i class="pi pi-envelope"></i>
              <p class="p-default">{{ dbsystemMail }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="dialog-section__footer">
        <div class="dialog-section__footer__left"></div>
        <div class="dialog-section__footer__right">
          <button
            pButton
            class="p-button-raised primary"
            label="{{ 'appComponent.startUsingThePlatform' | translate }}"
            [icon]="
              finishingTour ? 'pi pi-spin pi-spinner' : 'pi pi-arrow-right'
            "
            iconPos="right"
            (click)="completeTour()"
            [disabled]="finishingTour"
          ></button>
        </div>
      </div>
    </div>
  </p-dialog>
</section>

<p-toast position="bottom-right"></p-toast>

<div class="div-mobile-error">
  <img src="assets/images/errors/resolution.png" alt="" />

  <div class="texto">
    <h2 class="heading-2">
      {{ "components.yourScreenSizeIsNotSupported" | translate }}
    </h2>
    <p class="p-default" style="padding-top: 12px">
      {{ "components.yourScreenSizeMustToBe1024x680" | translate }}
    </p>
  </div>
</div>
