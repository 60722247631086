export interface ChangePasswordModel {
  currentPassword?: string;
  newPassword?: string;
}

export function ChangePasswordModel(
  currentPassword?: string,
  newPassword?: string
): ChangePasswordModel {
  return {
    currentPassword,
    newPassword,
  };
}
