import { inject, Injectable } from '@angular/core';
import { LocaleMessage } from '../../models/errors/LocaleMessage';
import { LanguageService } from '../language/language.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorsService {
  private languageService: LanguageService = inject(LanguageService);

  constructor() {}

  async verifyLocale(erro: any): Promise<LocaleMessage> {
    return new Promise((resolve, reject) => {
      let localeMessages = erro.userMessage;
      const lang = this.languageService.verifyLang();
      if (lang === 'pt') {
        resolve(localeMessages?.pt_BR!);
      } else if (lang === 'en') {
        resolve(localeMessages?.en_US!);
      } else if (lang === 'es') {
        resolve(localeMessages?.es_AR!);
      } else {
        resolve(localeMessages?.en_US!);
      }
    });
  }
}
