import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Amplify, Auth } from 'aws-amplify';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProfileApiService } from 'src/app/core/common/services/profile/profile.api.service';
import { environment } from 'src/environments/environment';
import { clearDataTablePreferences } from '../../../document-emitter/store/table-preferences.actions';
import { TablePreferencesState } from '../../../document-emitter/store/table-preferences.state';
import { removeLocalStorageItem } from '../../helpers/LocalStorageUtils';
import { clearContract } from '../../store/contract.actions';
import { IContractState } from '../../store/contract.state';
import { clearMenu, updateShowWelcomeTour } from '../../store/menu.actions';
import { IMenuState } from '../../store/menu.state';
import { ErrorsService } from '../error/errors.service';
import { LanguageService } from '../language/language.service';
import { TenantsService } from '../tenant/tenants.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private router: Router = inject(Router);
  private profileService: ProfileApiService = inject(ProfileApiService);
  private messageService: MessageService = inject(MessageService);
  private translate: TranslateService = inject(TranslateService);
  private langService: LanguageService = inject(LanguageService);
  private errorService: ErrorsService = inject(ErrorsService);
  private route: ActivatedRoute = inject(ActivatedRoute);
  private tenant = inject(TenantsService);
  private store: Store<{
    menu: IMenuState;
    tablePreferences: TablePreferencesState;
    contract: IContractState;
  }> = inject(
    Store<{
      menu: IMenuState;
      tablePreferences: TablePreferencesState;
      contract: IContractState;
    }>
  );

  readonly COLUMNCRT_KEY = 'col_CRT_propulsor';
  readonly COLUMNMIC_KEY = 'col_MIC_propulsor';
  readonly SELECTCOMPNAV_KEY = 'select_Company_nav_propulsor';

  private menuSubject = new BehaviorSubject<boolean>(false);
  showMenu$: Observable<boolean> = this.menuSubject.asObservable();
  menus$ = new Observable<IMenuState>();

  private signInErrorSubject = new BehaviorSubject<AuthLoginErrorTypes>({});
  signInErrorEvent$: Observable<AuthLoginErrorTypes> =
    this.signInErrorSubject.asObservable();

  private newPasswordErrorSubject = new BehaviorSubject<AuthLoginErrorTypes>(
    {}
  );
  newPasswordErrorEvent$: Observable<AuthLoginErrorTypes> =
    this.newPasswordErrorSubject.asObservable();

  user: any;
  redirectUrl: string = '';

  constructor() {
    Amplify.configure({
      Auth: environment.cognito,
    });
    this.menus$ = this.store.select('menu');
  }

  signIn(email: string, password: string): Promise<any> {
    return Auth.signIn(email, password)
      .then((user) => {
        this._removeDataFromLocalStorage();

        this.signInErrorSubject.next({
          message: '',
          detail: '',
        });

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.user = user;
          this.router.navigate(['/login/password-request']);
        } else {
          const navegarPara = this.redirectUrl ? this.redirectUrl : '/';
          this.router.navigateByUrl(navegarPara);
          this.redirectUrl = '';

          this.profileService
            .getProfile()
            .then((_) => {
              this.showMenu();
            })
            .catch((_) => {});
        }
      })
      .catch((error) => {
        if (error.code === 'NotAuthorizedException') {
          if (error.message === 'User is disabled.') {
            this.signInErrorSubject.next({
              message: 'User is disabled.',
              detail: this.translate.instant('messages.userDisabled'),
            });
          } else if (error.message === 'Incorrect username or password.') {
            this.signInErrorSubject.next({
              message: 'Incorrect username or password.',
              detail: this.translate.instant(
                'messages.emailOrPasswordAreIncorrect'
              ),
            });
          } else if (
            error.message ===
            'Temporary password has expired and must be reset by an administrator.'
          ) {
            this.signInErrorSubject.next({
              message:
                'Temporary password has expired and must be reset by an administrator.',
              detail: this.translate.instant(
                'messages.temporaryPasswordHasExpiredAndMustBeResetByAnAdministrator'
              ),
            });
          } else if (error.message === 'Refresh Token has expired') {
            this.signInErrorSubject.next({
              message: 'User does not exist.',
              detail: this.translate.instant('messages.userTokenHasExpired'),
            });

            this.signOut();
          }
        } else {
          if (error.message === 'No current user') this.signOut();
          this.errorService.verifyLocale(error.error).then((message) => {
            this._messageGeneralError(message.title!, message.message!);
          });
        }
      });
  }

  signOut(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._removeDataFromLocalStorage();

      Auth.signOut()
        .then(() => {
          this.hideMenu();
          this.langService.setLangFromBrowser();
          this.router.navigate(['/login']);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          this.hideMenu();
          this.router.navigate(['/login']);
          this.clearMenuOnSignOut();
          localStorage.removeItem('welcomeTourOpen');
          this.store.dispatch(
            updateShowWelcomeTour({ showWelcomeTour: false })
          );
          this.clearConfigTableUserState();
          this.clearContractState();
        });
    });
  }

  /**
   * @description Verifica se o usuário está autenticado
   * @returns Boolean se o usuário está autenticado ou não
   */
  async isAuthenticated(): Promise<boolean> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return !!user;
    } catch (error) {
      return false;
    }
  }

  showMenu() {
    this.menuSubject.next(true);
  }

  hideMenu() {
    this.menuSubject.next(false);
  }

  sessionValid(): Promise<boolean> {
    return Auth.currentAuthenticatedUser().then((user) => {
      return user.signInUserSession.isValid();
    });
  }

  forceChangePassword(newPassword: string): Promise<any> {
    return Auth.completeNewPassword(this.user, newPassword, null)
      .then(() => {
        this.router.navigate(['/'], { replaceUrl: true });

        this.profileService
          .getProfile()
          .then((_) => this.showMenu())
          .catch((_) => {});
      })
      .catch((_) => {});
  }

  forgotpassword(username: string): Promise<any> {
    return Auth.forgotPassword(username)
      .then((_) => {})
      .catch((error) => {
        if (error.code === 'LimitExceededException') {
          this.router.navigate(['/login/email-request-failed']);
        } else {
          this.errorService.verifyLocale(error.error).then((message) => {
            this._messageGeneralError(message.title!, message.message!);
          });
        }
      });
  }

  confirmForgotPassword(
    username: string,
    otp: string,
    newpassword: string
  ): Promise<any> {
    return Auth.forgotPasswordSubmit(username, otp, newpassword)
      .then((_) => {
        this.router.navigate(['/login/password-success']);

        this.newPasswordErrorSubject.next({
          message: '',
          detail: '',
        });
      })
      .catch((error) => {
        if (error.code === 'CodeMismatchException') {
          this.newPasswordErrorSubject.next({
            message: 'CodeMismatchException',
            detail: this.translate.instant(
              'components.theSecurityCodeProvidedIsInvalidPleaseTryAgainOrContactSupport'
            ),
          });
        } else if (error.code === 'ExpiredCodeException') {
          this.newPasswordErrorSubject.next({
            message: 'ExpiredCodeException',
            detail: this.translate.instant(
              'components.theSecurityCodeProvidedHasExpiredPleaseRequestANewCode'
            ),
          });
        } else {
          this.errorService.verifyLocale(error.error).then((message) => {
            this._messageGeneralError(message.title!, message.message!);
          });
        }
      });
  }

  private _messageGeneralError(title: string, message: string): void {
    return this.messageService.add({
      key: 'toast-login',
      severity: 'error',
      summary: title,
      detail: message,
    });
  }

  messageGeneralWarn(message: string): void {
    return this.messageService.add({
      key: 'toast-refresh',
      severity: 'warn',
      detail: message,
    });
  }

  clearMenuOnSignOut() {
    this.store.dispatch(clearMenu());
  }

  clearConfigTableUserState() {
    this.store.dispatch(clearDataTablePreferences());
  }

  /**
   * @description Limpar todos os estados do Contrato
   */
  clearContractState() {
    this.store.dispatch(clearContract());
  }

  /**
   * @description Remove alguns dados principais do localStorage
   */
  private _removeDataFromLocalStorage() {
    removeLocalStorageItem(this.COLUMNCRT_KEY);
    removeLocalStorageItem(this.COLUMNMIC_KEY);
    removeLocalStorageItem(this.SELECTCOMPNAV_KEY);
  }
}

export interface AuthLoginErrorTypes {
  message?: string;
  detail?: string;
}
