import { createAction, props } from '@ngrx/store';

export const setModulePermissions = createAction(
  '[Module] Set Module Permissions',
  props<{ permissions: any[] }>()
);

export const setContractPlan = createAction(
  '[Contract] Set Contract Plan',
  props<{ plan: any }>()
);

export const setContractExpirationDate = createAction(
  '[Contract] Set Contract Expiration Date',
  props<{ expirationDate: string }>()
);

export const setFinancialPendingBlockFrom = createAction(
  '[Contract] Set Financial Pending Block From',
  props<{ financialPendingBlockFrom: string }>()
);

export const clearContract = createAction('[Contract] Clear Contract');
