import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, take } from 'rxjs';

import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { ContractModel } from '../../models/contract/ContractModel';
import { ModuleContractedModel } from '../../models/contract/ModuleContractedModel';
import { AvailableMenusGetModel } from '../../models/menu/AvailableMenusGetModel';
import {
  setContractExpirationDate,
  setContractPlan,
  setFinancialPendingBlockFrom,
  setModulePermissions,
} from '../../store/contract.actions';
import { IContractState } from '../../store/contract.state';

@Injectable({
  providedIn: 'root',
})
export class TenantsService {
  private http: HttpClient = inject(HttpClient);
  private moduleStore: Store<{ contract: IContractState }> = inject(
    Store<{ contract: IContractState }>
  );

  tenantUrl: string;
  modulesPermission$ = new Observable<IContractState>();

  constructor() {
    this.tenantUrl = `${environment.apiUrl}v1/tenants`;
  }

  availableMenus(): Observable<AvailableMenusGetModel[]> {
    return this.http.get<AvailableMenusGetModel[]>(
      `${this.tenantUrl}/available-menus`
    );
  }

  getContract(): Promise<ContractModel> {
    return new Promise<ContractModel>((resolve, reject) => {
      this.http.get<ContractModel>(`${this.tenantUrl}/contract`).subscribe({
        next: (contract: ContractModel) => {
          let plan = contract.plan;

          this.moduleStore.dispatch(
            setModulePermissions({ permissions: plan?.propulsorModules! })
          );

          this.moduleStore.dispatch(setContractPlan({ plan }));

          this.moduleStore.dispatch(
            setContractExpirationDate({
              expirationDate: contract.expirationDate!,
            })
          );

          this.moduleStore.dispatch(
            setFinancialPendingBlockFrom({
              financialPendingBlockFrom: contract.financialPendingBlockFrom!,
            })
          );

          resolve(contract);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  /**
   * @description Retorna os módulos permitidos pelo contrato
   * @returns
   */
  getModules(): Promise<ModuleContractedModel[]> {
    return new Promise<ModuleContractedModel[]>((resolve, reject) => {
      let permissions: any;
      this.moduleStore
        .select((state) => state.contract)
        .subscribe((contract) => {
          permissions = contract.permissions;
        });

      if (permissions === undefined || permissions.length === 0) {
        this.getContract().then((result: any) => {
          this.modulesPermission$ = this.moduleStore.select('contract');
          resolve(result.plan.propulsorModules);
        });
      } else {
        resolve(permissions);
      }

      // this.modulesPermission$.subscribe((contract) => {
      //   let retorno = contract?.permissions;

      //   if (retorno === undefined || retorno.length === 0) {

      //   } else {
      //   }
      // });
    });
  }

  hasModulePermission(module: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let permissions: any;
      this.modulesPermission$ = this.moduleStore.select('contract');

      this.modulesPermission$.pipe(take(1)).subscribe((data) => {
        let retorno = data?.permissions;
        if (retorno === undefined || retorno.length === 0) {
          this.getContract().then(async (result: any) => {
            this.modulesPermission$ = this.moduleStore.select('contract');
            resolve(this.checkPermission(result.plan.propulsorModules, module));
          });
        } else {
          permissions = retorno;
          resolve(this.checkPermission(permissions, module));
        }
      });
    });
  }

  checkPermission(menus: ModuleContractedModel[], module: string): boolean {
    return menus.some((menu) => menu.name === module);
  }
}
