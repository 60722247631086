<div
  class="menu"
  [style.overflow-y]="stateMenuLocal === true ? 'hidden' : ''"
  [style.overflow-x]="stateMenuLocal === true ? 'hidden' : ''"
  [class]="showMainMenu === true ? 'show-main-menu' : 'close-menu'"
>
  <div class="l-navbar" id="nav-bar">
    <nav class="nav">
      <app-load-menu *ngIf="loadingMenu"></app-load-menu>
      <div *ngIf="!loadingMenu" class="flex nav_container">
        <div class="nav_list">
          <a
            *ngFor="let item of menuItems"
            class="nav_link active {{
              item.disabled === true ? 'menu-disabled' : ''
            }}"
            pTooltip="{{ item.tooltip }}"
            tooltipPosition="right"
            [showDelay]="300"
            [hidden]="item.disabled === true"
            (click)="item.disabled === true ? '' : item?.action()"
            [style.background-color]="
              item.icon === colorSelected() ? '#37404c' : ''
            "
          >
            <img src="assets/images/icons/{{ item.icon }}-icon.png" alt="" />
          </a>
        </div>

        <a
          class="p-default version_text"
          [pTooltip]="'common.versionNumber' | translate : { number: version }"
          tooltipPosition="right"
          [showDelay]="300"
          >v {{ version }}</a
        >
      </div>
    </nav>
  </div>

  <div
    class="submenu"
    id="submenu"
    [class]="
      stateMenuLocal === true
        ? 'open-animation open-menu'
        : 'close-animation close-menu'
    "
  >
    <p-scrollPanel
      [style]="{ height: 'calc(100vh - 50px)' }"
      styleClass="custombar2"
    >
      <p-panelMenu
        id="emitterMenu"
        *ngIf="emitter"
        [model]="emitterSubmenus"
        [style]="{ width: '180px' }"
        [multiple]="false"
      ></p-panelMenu>

      <p-panelMenu
        id="followUpMenu"
        *ngIf="followUp"
        [model]="followUpSubmenus"
        [style]="{ width: '180px' }"
        [multiple]="false"
      ></p-panelMenu>

      <p-panelMenu
        id="tmsMenu"
        *ngIf="tms"
        [model]="tmsSubmenus"
        [style]="{ width: '180px' }"
        [multiple]="false"
      ></p-panelMenu>

      <p-panelMenu
        id="journeyMenu"
        *ngIf="journey"
        [model]="journeySubmenus"
        [style]="{ width: '180px' }"
        [multiple]="false"
      ></p-panelMenu>

      <p-panelMenu
        id="coopMenu"
        *ngIf="coop"
        [model]="coopSubmenus"
        [style]="{ width: '180px' }"
        [multiple]="false"
      ></p-panelMenu>

      <p-panelMenu
        id="wmsMenu"
        *ngIf="wms"
        [model]="wmsSubmenus"
        [style]="{ width: '180px' }"
        [multiple]="false"
      ></p-panelMenu>

      <p-panelMenu
        id="managerMenu"
        *ngIf="manager"
        [model]="managerSubmenus"
        [style]="{ width: '180px' }"
        [multiple]="false"
      ></p-panelMenu>

      <p-panelMenu
        id="registrationsMenu"
        *ngIf="registrations"
        [model]="registrationsSubmenus"
        [style]="{ width: '180px' }"
        [multiple]="false"
      ></p-panelMenu>
    </p-scrollPanel>
  </div>
</div>
