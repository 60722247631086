import { Observable } from 'rxjs';
import { decryptBase64, encryptBase64 } from './CryptoUtils';

/**
 * @description Função para salvar um item no localStorage com criptografia
 * @param key Chave do item a ser armazenado
 * @param {any} value Valor do item a ser armazenado
 */
export function setLocalStorageItem(key: string, value: any) {
  localStorage?.setItem(key, encryptBase64(JSON?.stringify(value)));
}

/**
 * @description Função para salvar um item no localStorage sem criptografia
 * @param key Chave do item a ser armazenado
 * @param {any} value Valor do item a ser armazenado
 */
export function setLocalStorageItemNoEncrypt(key: string, value: any) {
  localStorage?.setItem(key, JSON?.stringify(value));
}

/**
 * @description Função para retornar um item do localStorage com criptografia
 * @param {string} key Chave do item a ser retornado
 * @returns {string | null} Item do localStorage
 */
export function getLocalStorageItem(key: string): string | null {
  let item = localStorage?.getItem(key);
  if (item) return JSON?.parse(decryptBase64(item));
  else return null;
}

/**
 * @description Função para retornar um item do localStorage sem criptografia
 * @param {string} key Chave do item a ser retornado
 * @returns {string | null} Item do localStorage
 */
export function getLocalStorageItemNoDecrypt(key: string): string | null {
  let item = localStorage?.getItem(key);
  if (item) return JSON?.parse(item);
  else return null;
}

/**
 * @description Função para retornar um item do localStorage como promise com criptografia
 * @param {string} key Chave do item a ser retornado
 * @returns {Promise<string | null>} Item do localStorage como Promise
 */
export function getLocalStorageItemPromise(
  key: string
): Promise<string | null> {
  return new Promise((resolve, reject) => {
    let item = localStorage?.getItem(key);
    if (item) resolve(JSON?.parse(decryptBase64(item)));
    else resolve(null);
  });
}

/**
 * @description Função para retornar um item do localStorage como promise sem criptografia
 * @param {string} key Chave do item a ser retornado
 * @returns {Promise<string | null>} Item do localStorage como Promise
 */
export function getLocalStorageItemPromiseNoDecrypt(
  key: string
): Promise<string | null> {
  return new Promise((resolve, reject) => {
    let item = localStorage?.getItem(key);
    if (item) resolve(JSON?.parse(item));
    else resolve(null);
  });
}

/**
 * @description Função para retornar um item do localStorage como observable com criptografia
 * @param {string} key Chave do item a ser retornado
 * @returns {Observable<string | null>} Item do localStorage como Observable
 */
export function getLocalStorageItemObservable(
  key: string
): Observable<string | null> {
  return new Observable((observer) => {
    let item = localStorage?.getItem(key);
    if (item) observer.next(JSON?.parse(decryptBase64(item)));
    else observer.next(null);
  });
}

/**
 * @description Função para retornar um item do localStorage como observable sem criptografia
 * @param {string} key Chave do item a ser retornado
 * @returns {Observable<string | null>} Item do localStorage como Observable
 */
export function getLocalStorageItemObservableNoDecrypt(
  key: string
): Observable<string | null> {
  return new Observable((observer) => {
    let item = localStorage?.getItem(key);
    if (item) observer.next(JSON?.parse(item));
    else observer.next(null);
  });
}

/**
 * @description Função para remover um item do localStorage
 * @param key Chave do item a ser removido
 */
export function removeLocalStorageItem(key: string) {
  localStorage?.removeItem(key);
}

/**
 * @description Função para remover um item do localStorage como Promise
 * @param key  Chave do item a ser removido
 * @returns {Promise<boolean>} Retorna true quando o item for removido
 */
export function removeLocalStorageItemPromise(key: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    localStorage?.removeItem(key);
    resolve(true);
  });
}

/**
 * @description Função para remover um item do localStorage como Observable
 * @param key  Chave do item a ser removido
 * @returns {Observable<boolean>} Retorna true quando o item for removido
 */
export function removeLocalStorageItemObservable(
  key: string
): Observable<boolean> {
  return new Observable((observer) => {
    localStorage?.removeItem(key);
    observer.next(true);
  });
}

/**
 * @description Função para limpar o localStorage inteiro
 */
export function clearLocalStorage() {
  localStorage?.clear();
}

/**
 * @description Função para limpar o localStorage inteiro como Promise
 * @returns {Promise<boolean>} Retorna true quando o localStorage for limpo
 */
export function clearLocalStoragePromise(): Promise<boolean> {
  return new Promise((resolve, reject) => {
    localStorage?.clear();
    resolve(true);
  });
}

/**
 * @description Função para limpar o localStorage inteiro como Observable
 * @returns {Observable<boolean>} Retorna true quando o localStorage for limpo
 */
export function clearLocalStorageObservable(): Observable<boolean> {
  return new Observable((observer) => {
    localStorage?.clear();
    observer.next(true);
  });
}
