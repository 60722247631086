import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig, Translation } from 'primeng/api';
import { ProfileModel } from '../../models/profile/ProfileModel';

const userLangNgx = 'user_language_propulsor_ngx';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private translate: TranslateService = inject(TranslateService);
  private primengConfig: PrimeNGConfig = inject(PrimeNGConfig);

  availableLangs = ['pt', 'es'];
  defaultLang = 'pt';
  defaultLangFull = 'pt-BR';

  constructor() {}

  /**
   * Função que pega a linguagem do usuário do localStorage e aplica para o translate.
   * @returns {Promise<void>} Retorna uma promisse vazia.
   */
  setLangFromLocalStorage(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const lang = this.getLocalSttorageLangAndVerify();
      const translations = require(`../../../../../assets/i18n/${lang}.json`);

      if (lang) {
        if (this.availableLangs.includes(lang)) {
          this.useLanguageAndInjectPrime(lang, translations);
        } else {
          this.useLanguageAndInjectPrime(this.defaultLang, translations);
        }
      } else {
        this.useLanguageAndInjectPrime(this.defaultLang, translations);
      }

      resolve();
    });
  }

  /**
   * Função que aplica uma nova linguagem para o localStorage e também aplica para o translate.
   * @param {string} newLang Nova linguagem que será aplicada.
   * @returns {Promise<any>} Retorna uma promisse vazia.
   */
  setLangToLocalStorageAndUpdateTranslate(newLang: string): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.setLocalStorageLang(newLang);
      const lang = this.getLocalSttorageLangAndVerify();
      const translations = require(`../../../../../assets/i18n/${lang}.json`);

      if (lang) {
        if (this.availableLangs.includes(lang)) {
          this.useLanguageAndInjectPrime(lang, translations);
        } else {
          this.useLanguageAndInjectPrime(this.defaultLang, translations);
        }
      } else {
        this.useLanguageAndInjectPrime(this.defaultLang, translations);
      }

      resolve();
    });
  }

  /**
   * Função que pega o idioma do usuário e aplica para o translate.
   * @param {ProfileModel} user Usuário logado no sistema.
   */
  setInitialLang(user: ProfileModel): Promise<void> {
    return new Promise((resolve, reject) => {
      const initialLettersLang = user.language?.split('_')[0].toLowerCase();
      const browserLang = this.getBrowserLangAndVerify();

      user.language
        ? this.setLocalStorageLang(user.language)
        : this.setLocalStorageLang(this.defaultLangFull);

      if (initialLettersLang) {
        if (this.availableLangs.includes(initialLettersLang)) {
          const translations = require(`../../../../../assets/i18n/${initialLettersLang}.json`);
          this.useLanguageAndInjectPrime(initialLettersLang, translations);
        } else {
          if (browserLang) {
            if (this.availableLangs.includes(browserLang)) {
              const translations = require(`../../../../../assets/i18n/${browserLang}.json`);
              this.useLanguageAndInjectPrime(browserLang, translations);
            } else {
              const translations = require(`../../../../../assets/i18n/${this.defaultLang}.json`);
              this.useLanguageAndInjectPrime(this.defaultLang, translations);
            }
          }
        }
      } else {
        if (browserLang) {
          if (this.availableLangs.includes(browserLang)) {
            const translations = require(`../../../../../assets/i18n/${browserLang}.json`);
            this.useLanguageAndInjectPrime(browserLang, translations);
          } else {
            const translations = require(`../../../../../assets/i18n/${this.defaultLang}.json`);
            this.useLanguageAndInjectPrime(this.defaultLang, translations);
          }
        }
      }
    });
  }

  /**
   * Função que pega a linguagem do navegador e aplica para o translate, ela é chamada quando o usuário faz logout do sistema, o sistema não carrega a página novamente e com isso não atualiza a linguagem por conta própria, então essa função é necessária nesse momento.
   * @returns {Promise<void>} Retorna uma promisse vazia.
   */
  setLangFromBrowser(): Promise<void> {
    return new Promise((resolve, reject) => {
      const lang = this.getBrowserLangAndVerify();
      this.removeLocalStorageLang();

      if (lang) {
        if (this.availableLangs.includes(lang)) {
          const translations = require(`../../../../../assets/i18n/${lang}.json`);
          this.useLanguageAndInjectPrime(lang, translations);
        } else {
          const translations = require(`../../../../../assets/i18n/${this.defaultLang}.json`);
          this.useLanguageAndInjectPrime(this.defaultLang, translations);
        }
      } else {
        const translations = require(`../../../../../assets/i18n/${this.defaultLang}.json`);
        this.useLanguageAndInjectPrime(this.defaultLang, translations);
      }

      resolve();
    });
  }

  /**
   * Função que detecta a linguagem do navegador.
   * @returns {string | undefined} Retorna a linguagem do navegador, se não encontrar retorna undefined.
   */
  detectBrowserLang(): string | undefined {
    return this.translate.getBrowserLang();
  }

  /**
   * Função que retorna a linguagem do usuario sálva nop localStorage.
   * @returns {string | null} Retorna a linguagem do usuário, se não encontrar retorna undefined.
   */
  getLocalStorageLang(): string | null {
    return localStorage.getItem(userLangNgx);
  }

  /**
   * Função que salva a linguagem do usuário no localStorage.
   * @param {string} lang Linguagem que será salva no localStorage.
   */
  setLocalStorageLang(lang: string): void {
    localStorage.setItem(userLangNgx, lang.toLowerCase());
  }

  /**
   * Função que remove a linguagem do usuário do localStorage.
   */
  removeLocalStorageLang(): void {
    localStorage.removeItem(userLangNgx);
  }

  /**
   * Função que pega a linguagem do localStorage e verifica se ela está no padrão (ex: pt-br ou pt_br) e retorna só a primeira parte (ex: pt).
   * @returns {string} Retorna a linguagem do localStorage, se não encontrar retorna a linguagem padrão.
   */
  private getLocalSttorageLangAndVerify(): string {
    let returnLang = null;
    let lsLang = this.getLocalStorageLang();

    if (lsLang) {
      if (lsLang.includes('-')) {
        returnLang = lsLang.split('-')[0];
      }

      if (lsLang.includes('_')) {
        returnLang = lsLang.split('_')[0];
      }
    }

    return returnLang ? returnLang : this.defaultLang;
  }

  /**
   * Função que pega a linguagem do navegador e verifica se ela está no padrão (ex: pt-br ou pt_br) e retorna só a primeira parte (ex: pt).
   * @returns {string} Retorna a linguagem do navegador, se não encontrar retorna a linguagem padrão.
   */
  private getBrowserLangAndVerify(): string {
    let returnLang = null;
    let browserLang = this.detectBrowserLang();

    if (browserLang) {
      if (browserLang.includes('-')) {
        returnLang = browserLang.split('-')[0];
      }

      if (browserLang.includes('_')) {
        returnLang = browserLang.split('_')[0];
      }
    }

    return returnLang ? returnLang : this.defaultLang;
  }

  /**
   * Função para eliminar a repetição de código.
   * @description
   * @param {string} lang Linguagem que será aplicada.
   * @param {Translation} translate Arquivo de tradução completo que será aplicado
   */
  private useLanguageAndInjectPrime(
    lang: string,
    translate: Translation
  ): void {
    this.translate.setTranslation(lang, translate);
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.primengConfig.setTranslation(translate);
  }

  /**
   * Verifica se a linguagem está salva no localStorage, se não estiver verifica a linguagem do navegador.
   * @returns string Retorna a linguagem que será aplicada.
   */
  verifyLang() {
    let lang = this.getLocalSttorageLangAndVerify();
    if (lang === undefined || lang === null) {
      lang = this.getBrowserLangAndVerify();
    }
    return lang;
  }

  updatePrimeNgTranslate() {
    this.primengConfig.setTranslation({
      apply: this.translate.instant('enums.primeNgConfig.apply'),
      clear: this.translate.instant('enums.primeNgConfig.clear'),
      accept: this.translate.instant('enums.primeNgConfig.accept'),
      reject: this.translate.instant('enums.primeNgConfig.reject'),
      today: this.translate.instant('enums.primeNgConfig.today'),
      firstDayOfWeek: 0,
      dayNames: [
        this.translate.instant('enums.dayNames.SUNDAY'),
        this.translate.instant('enums.dayNames.MONDAY'),
        this.translate.instant('enums.dayNames.TUESDAY'),
        this.translate.instant('enums.dayNames.WEDNESDAY'),
        this.translate.instant('enums.dayNames.THURSDAY'),
        this.translate.instant('enums.dayNames.FRIDAY'),
        this.translate.instant('enums.dayNames.SATURDAY'),
      ],
      dayNamesShort: [
        this.translate.instant('enums.dayNamesShort.SUN'),
        this.translate.instant('enums.dayNamesShort.MON'),
        this.translate.instant('enums.dayNamesShort.TUE'),
        this.translate.instant('enums.dayNamesShort.WED'),
        this.translate.instant('enums.dayNamesShort.THU'),
        this.translate.instant('enums.dayNamesShort.FRI'),
        this.translate.instant('enums.dayNamesShort.SAT'),
      ],
      dayNamesMin: [
        this.translate.instant('enums.dayNamesMin.SU'),
        this.translate.instant('enums.dayNamesMin.MO'),
        this.translate.instant('enums.dayNamesMin.TU'),
        this.translate.instant('enums.dayNamesMin.WE'),
        this.translate.instant('enums.dayNamesMin.TH'),
        this.translate.instant('enums.dayNamesMin.FR'),
        this.translate.instant('enums.dayNamesMin.SA'),
      ],
      monthNames: [
        this.translate.instant('enums.monthNames.JANUARY'),
        this.translate.instant('enums.monthNames.FEBRUARY'),
        this.translate.instant('enums.monthNames.MARCH'),
        this.translate.instant('enums.monthNames.APRIL'),
        this.translate.instant('enums.monthNames.MAY'),
        this.translate.instant('enums.monthNames.JUNE'),
        this.translate.instant('enums.monthNames.JULY'),
        this.translate.instant('enums.monthNames.AUGUST'),
        this.translate.instant('enums.monthNames.SEPTEMBER'),
        this.translate.instant('enums.monthNames.OCTOBER'),
        this.translate.instant('enums.monthNames.NOVEMBER'),
        this.translate.instant('enums.monthNames.DECEMBER'),
      ],
      monthNamesShort: [
        this.translate.instant('enums.monthNamesShort.JAN'),
        this.translate.instant('enums.monthNamesShort.FEB'),
        this.translate.instant('enums.monthNamesShort.MAR'),
        this.translate.instant('enums.monthNamesShort.APR'),
        this.translate.instant('enums.monthNamesShort.MAY'),
        this.translate.instant('enums.monthNamesShort.JUN'),
        this.translate.instant('enums.monthNamesShort.JUL'),
        this.translate.instant('enums.monthNamesShort.AUG'),
        this.translate.instant('enums.monthNamesShort.SEP'),
        this.translate.instant('enums.monthNamesShort.OCT'),
        this.translate.instant('enums.monthNamesShort.NOV'),
        this.translate.instant('enums.monthNamesShort.DEC'),
      ],
    });
  }
}
