import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PersonType } from '../../../registrations/enums/PersonType';
import { AddressPostModel } from '../../../registrations/models/addresses/AddressPostModel';
import { ContactPostModel } from '../../../registrations/models/contacts/ContactPostModel';
import { DocumentsPersonPostModel } from '../../../registrations/models/documents/DocumentsPersonPostModel';
import { MyCompanyFilter } from '../../filters/MyCompanyFilter';
import { CompanyPostModel } from '../../models/companies/CompanyPostModel';
import { CompanyPutModel } from '../../models/companies/CompanyPutModel';

@Injectable({
  providedIn: 'root',
})
export class CompanyServiceApi {
  private http: HttpClient = inject(HttpClient);

  companyUrl!: string;
  personType = PersonType;
  private companySubject = new BehaviorSubject<any[]>([]);
  private changeCompanySource = new BehaviorSubject<boolean>(false);
  isCompanyChanged$ = this.changeCompanySource.asObservable();

  constructor() {
    this.companyUrl = `${environment.apiUrl}v1/tenants/my-company`;
  }

  /**
   * Notifica que a empresa foi alterada para escutar e atualizar algum componente
   */
  notifyChangeCompany() {
    this.changeCompanySource.next(true);
  }

  getCompany(filtro: MyCompanyFilter): Observable<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.page.toString(),
        size: filtro.size.toString(),
        sort: 'id,desc',
      },
    });

    if (filtro.active) {
      params = params.set('active', filtro.active.toString());
    }

    if (filtro.type) {
      params = params.set('type', filtro.type.toString());
    }

    if (filtro.headquarter) {
      params = params.set('headquarter', filtro.headquarter.toString());
    }

    if (filtro.personName) {
      params = params.set('personName', filtro.personName.toString());
    }

    return this.http.get(this.companyUrl, { params });
  }

  getCompanyForDropdown(): Observable<any[]> {
    let filtro: MyCompanyFilter = new MyCompanyFilter();
    filtro.active = true;
    filtro.size = 40;
    let params = new HttpParams({
      fromObject: {
        page: filtro.page.toString(),
        size: filtro.size.toString(),
      },
    });

    if (filtro.active) {
      params = params.set('active', filtro.active.toString());
    }

    var resultForDropdown: any[] = [];

    this.http.get<any>(this.companyUrl, { params }).subscribe({
      next: (data) => {
        data.content.forEach((item: any) => {
          resultForDropdown.push({
            label: item.person.personName,
            value: item.id,
          });
        });
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
        this.companySubject.next(resultForDropdown);
      },
    });
    return this.companySubject.asObservable();
  }

  getCompanyById(idCompany: number): Observable<any> {
    return this.http.get(`${this.companyUrl}/${idCompany}`);
  }

  getDocuments(idCompany: number): Observable<any> {
    return this.http.get(`${this.companyUrl}/${idCompany}/document`);
  }

  getDocumentById(idClient: number, idDocument: number): Observable<any> {
    return this.http.get(
      `${this.companyUrl}/${idClient}/document/${idDocument}`
    );
  }

  getAddresses(idCompany: number): Observable<any> {
    return this.http.get(`${this.companyUrl}/${idCompany}/address`);
  }

  getContacts(idCompany: number): Observable<any> {
    return this.http.get(`${this.companyUrl}/${idCompany}/contact`);
  }

  saveCompany(companyModel: CompanyPostModel) {
    return this.http.post(this.companyUrl, companyModel);
  }

  saveDocument(idCompany: number, documentModel: DocumentsPersonPostModel) {
    return this.http.post(
      `${this.companyUrl}/${idCompany}/document`,
      documentModel
    );
  }

  saveContact(idCompany: number, contactModel: ContactPostModel) {
    return this.http.post(
      `${this.companyUrl}/${idCompany}/contact`,
      contactModel
    );
  }

  saveAddress(idCompany: number, addressModel: AddressPostModel) {
    return this.http.post(
      `${this.companyUrl}/${idCompany}/address`,
      addressModel
    );
  }

  editCompany(idCompany: number, companyModel: CompanyPutModel) {
    return this.http.put(`${this.companyUrl}/${idCompany}`, companyModel);
  }

  editDocument(
    idCompany: number,
    idDocument: number,
    documentModel: DocumentsPersonPostModel
  ) {
    return this.http.put(
      `${this.companyUrl}/${idCompany}/document/${idDocument}`,
      documentModel
    );
  }

  editAddress(
    idCompany: number,
    idAddress: number,
    addressModel: AddressPostModel
  ) {
    return this.http.put(
      `${this.companyUrl}/${idCompany}/address/${idAddress}`,
      addressModel
    );
  }

  editContact(
    idCompany: number,
    idContact: number,
    contactModel: ContactPostModel
  ) {
    return this.http.put(
      `${this.companyUrl}/${idCompany}/contact/${idContact}`,
      contactModel
    );
  }

  deleteCompany(idCompany: number) {
    return this.http.delete(`${this.companyUrl}/${idCompany}`);
  }

  deleteDocument(idCompany: number, idDocument: number) {
    return this.http.delete(
      `${this.companyUrl}/${idCompany}/document/${idDocument}`
    );
  }

  deleteAddress(idCompany: number, idAddress: number) {
    return this.http.delete(
      `${this.companyUrl}/${idCompany}/address/${idAddress}`
    );
  }

  deleteContact(idCompany: number, idContact: number) {
    return this.http.delete(
      `${this.companyUrl}/${idCompany}/contact/${idContact}`
    );
  }

  enableCompany(idCompany: number) {
    return this.http.patch(`${this.companyUrl}/${idCompany}/enable`, {});
  }

  disableCompany(idCompany: number) {
    return this.http.patch(`${this.companyUrl}/${idCompany}/disable`, {});
  }
}
