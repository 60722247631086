import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class TokenErrorsInterceptor implements HttpInterceptor {
  private authService: AuthService = inject(AuthService);
  private translate: TranslateService = inject(TranslateService);

  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Captura erros relacionados ao refresh token, como expirado ou inválido
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.message === 'No current user') {
          this.authService.messageGeneralWarn(
            this.translate.instant('messages.noCurrentUser')
          );
          this.authService.signOut();
        } else if (error.message === 'Refresh Token has expired') {
          this.authService.messageGeneralWarn(
            this.translate.instant('messages.sessionExpired')
          );
          this.authService.signOut();
        } else if (error.message === 'Invalid Refresh Token') {
          this.authService.messageGeneralWarn(
            this.translate.instant('messages.refreshTokenInvalid')
          );
          this.authService.signOut();
        }
        return throwError(() => error);
      })
    );
  }
}
