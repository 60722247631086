import { createReducer, on } from '@ngrx/store';
import {
  clearContract,
  setContractExpirationDate,
  setContractPlan,
  setFinancialPendingBlockFrom,
  setModulePermissions,
} from './contract.actions';

export interface IContractState {
  permissions: any[];
  plan?: any;
  expirationDate?: string;
  financialPendingBlockFrom: string | null;
}

export const initialState: IContractState = {
  permissions: [],
  plan: undefined,
  expirationDate: undefined,
  financialPendingBlockFrom: null,
};

export const contractReducer = createReducer(
  initialState,
  on(setModulePermissions, (state, { permissions }) => ({
    ...state,
    permissions,
  })),

  on(setContractPlan, (state, { plan }) => ({
    ...state,
    plan,
  })),

  on(setContractExpirationDate, (state, { expirationDate }) => ({
    ...state,
    expirationDate,
  })),

  on(setFinancialPendingBlockFrom, (state, { financialPendingBlockFrom }) => ({
    ...state,
    financialPendingBlockFrom,
  })),

  on(clearContract, () => initialState)
);
