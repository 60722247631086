import { TableLazyLoadEvent } from 'primeng/table';

export abstract class Pageable {
  public page: number = 0;
  public size: number = 10;
  public sortField: string | string[] = '';
  public sortOrder: string = '';
  public isFiltering = false;
  private typingTimer: any;

  aoMudarPagina(event: TableLazyLoadEvent): void {
    this.size = event.rows!;
    this.page = event.first! / event.rows!;
    this.sortField = event.sortField!;
    this.sortOrder = event.sortOrder?.toString() === '1' ? 'asc' : 'desc';
  }

  isFilteringAny(): boolean | undefined {
    if (this.isFiltering) {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(() => {
        this.isFiltering = false;
        return true;
      }, 500);
      return;
    } else {
      this.isFiltering = true;
      return true;
    }
  }
}

export const MAX_RESULT_PER_PAGE = 2147483647;
