const CryptoJS = require('crypto-js');

/**
 * @description Encripta uma string em uma string base64
 * @param {any} value Valor a ser encriptado
 * @returns {string} Valor encriptado em base64
 */
export function encryptBase64(value: any): string {
  const wordArray = CryptoJS.enc.Utf8.parse(value);
  const base64 = CryptoJS.enc.Base64.stringify(wordArray);
  return base64;
}

/**
 * @description Decripta uma string base64 no valor original
 * @param base64 Valor base64 a ser decriptado
 * @returns {string} Valor original decriptado
 */
export function decryptBase64(base64: any): string {
  const parsedWordArray = CryptoJS.enc.Base64.parse(base64);
  const parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8);
  return parsedStr;
}
