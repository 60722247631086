export enum ItemMenuCode {
  COMMERCIAL_INVOICE = 'COMMERCIAL_INVOICE',
  CRT = 'CRT',
  CTE = 'CTE',
  MDFE = 'MDFE',
  MIC = 'MIC',
  ADUANA_CUSTOMS_AND_ENCLOSURES = 'ADUANA_CUSTOMS_AND_ENCLOSURES',
  SISCOMEX_ROUTE = 'SISCOMEX_ROUTE',
  SUITABILITIES = 'SUITABILITIES',
  INSURANCE_POLICY = 'INSURANCE_POLICY',
  COMPANIES_AND_CLIENTS = 'COMPANIES_AND_CLIENTS',
  PROVIDERS = 'PROVIDERS',
  PLACES = 'PLACES',
  DRIVERS = 'DRIVERS',
  VEHICLES = 'VEHICLES',
  CURRENCIES = 'CURRENCIES',
  MY_COMPANIES = 'MY_COMPANIES',
  USERS = 'USERS',
  PLAN_AND_BILLING = 'PLAN_AND_BILLING',
  DIGITAL_CERTIFICATE = 'DIGITAL_CERTIFICATE',
  PUCOMEX_INTEGRATION = 'PUCOMEX_INTEGRATION',
  MULTILOG_INTEGRATION = 'MULTILOG_INTEGRATION',
  FOLLOW_UP_MIC_EVENTS = 'FOLLOW_UP_MIC_EVENTS',
  FOLLOW_UP_MIC_APPOINTMENT = 'FOLLOW_UP_MIC_APPOINTMENT',
  FOLLOW_UP_MIC_DASHBOARD = 'FOLLOW_UP_MIC_DASHBOARD',
}

export const ItemMenuCodeLabel = new Map<ItemMenuCode, string>([
  [ItemMenuCode.COMMERCIAL_INVOICE, 'Fatura Comercial'],
  [ItemMenuCode.CRT, 'CRT'],
  [ItemMenuCode.CTE, 'CT-e'],
  [ItemMenuCode.MDFE, 'MDF-e'],
  [ItemMenuCode.MIC, 'MIC'],
  [ItemMenuCode.ADUANA_CUSTOMS_AND_ENCLOSURES, 'Aduanas e Recintos'],
  [ItemMenuCode.SISCOMEX_ROUTE, 'Rotas Siscomex'],
  [ItemMenuCode.SUITABILITIES, 'Idoneidades'],
  [ItemMenuCode.INSURANCE_POLICY, 'Apólice de Seguro'],
  [ItemMenuCode.COMPANIES_AND_CLIENTS, 'Empresas e Clientes'],
  [ItemMenuCode.PROVIDERS, 'Fornecedores'],
  [ItemMenuCode.PLACES, 'Localidades'],
  [ItemMenuCode.DRIVERS, 'Motoristas'],
  [ItemMenuCode.VEHICLES, 'Veículos'],
  [ItemMenuCode.CURRENCIES, 'Moedas'],
  [ItemMenuCode.MY_COMPANIES, 'Minhas Empresas'],
  [ItemMenuCode.USERS, 'Usuários'],
  [ItemMenuCode.PLAN_AND_BILLING, 'Plano e Faturamento'],
  [ItemMenuCode.DIGITAL_CERTIFICATE, 'Certificado Digital'],
  [ItemMenuCode.PUCOMEX_INTEGRATION, 'Integração Pucomex'],
  [ItemMenuCode.MULTILOG_INTEGRATION, 'Integração Multilog'],
  [ItemMenuCode.FOLLOW_UP_MIC_EVENTS, 'Eventos'],
  [ItemMenuCode.FOLLOW_UP_MIC_APPOINTMENT, 'Agendamentos'],
  [ItemMenuCode.FOLLOW_UP_MIC_DASHBOARD, 'Dashboard'],
]);
