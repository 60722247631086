import { createAction, props } from '@ngrx/store';
import { ColumnsTableCustom } from '../interfaces/ColumnsTableCustom';

export const addColsSelectedsCRT = createAction(
  '[CRT] Add Cols Selecteds',
  props<{ colsCRT: ColumnsTableCustom[] }>()
);

export const addColsSelectedsMIC = createAction(
  '[MIC] Add Cols Selecteds',
  props<{ colsMIC: ColumnsTableCustom[] }>()
);

export const clearDataTablePreferences = createAction(
  '[CRT, MIC] Clear Cols and Branches Selecteds'
);
