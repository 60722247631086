<div class="load-content">
  <p-skeleton shape="square" size="24px" class="load-icon"></p-skeleton>
  <p-skeleton shape="square" size="24px"></p-skeleton>
  <!--
  // Comentado devido a nova disposição dos itens do menu.
  <p-skeleton shape="square" size="24px"></p-skeleton>
   <p-skeleton shape="square" size="24px"></p-skeleton>
  <p-skeleton shape="square" size="24px"></p-skeleton>
  <p-skeleton shape="square" size="24px"></p-skeleton>
  <p-skeleton shape="square" size="24px"></p-skeleton>
  <p-skeleton shape="square" size="24px"></p-skeleton> -->
</div>
