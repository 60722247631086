<div class="div-error">
  <img src="assets/images/errors/404.png" alt="" />

  <div class="texto">
    <h2 class="heading-2">{{ "components.pageNotFound" | translate }}</h2>
    <p class="p-default">
      {{ "components.screenNotExistCheckLink" | translate }}
    </p>
  </div>

  <button
    pButton
    label="{{ 'components.goToHome' | translate }}"
    icon="pi pi-home"
    class="p-button-raised primary"
    (click)="goTo()"
  ></button>
</div>
