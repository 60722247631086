import { inject, Injectable } from '@angular/core';

import { ProfileApiService } from 'src/app/core/common/services/profile/profile.api.service';
import { TenantsService } from '../tenant/tenants.service';
import { VersionCheckService } from '../update-service/udpate.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  private profile: ProfileApiService = inject(ProfileApiService);
  private tenant = inject(TenantsService);
  private version = inject(VersionCheckService);

  constructor() {}

  Init() {
    return new Promise<void>((resolve, reject) => {
      let pathName = window.location.pathname;
      if (pathName !== '/login') {
        this.profile.getProfile();
        this.tenant.getContract();
        this.version.checkForUpdates();
      }

      setTimeout(() => {
        resolve();
      }, 1000);
    });
  }
}
