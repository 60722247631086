export interface ProfilePutModel {
  name?: string;
  language?: string;
}

export function ProfilePutModel(
  name?: string,
  language?: string
): ProfilePutModel {
  return {
    name,
    language,
  };
}
