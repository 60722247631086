import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { AuthService } from 'src/app/core/common/services/auth/auth.service';

/**
 * * Componente de erro 404
 * @description Tela de erro 404 (não encontrado) que é exibida quando o usuário tenta acessar uma rota que não existe.
 * @author Dariane Abich
 * @date 24/04/2024
 * @export
 * @class Erro404Component
 * @implements {OnInit}
 */
@Component({
  selector: 'app-erro404',
  templateUrl: './erro404.component.html',
  styleUrls: ['./erro404.component.scss'],
  standalone: true,
  imports: [ButtonModule, TranslateModule],
})
export class Erro404Component implements OnInit {
  private authService: AuthService = inject(AuthService);
  private router: Router = inject(Router);

  isLoggedIn: any;

  constructor() {
    this.authService.hideMenu();
  }

  ngOnInit(): void {
    this.authService.sessionValid().then((res) => (this.isLoggedIn = res));
  }

  /**
   * @description Redirecionar para o início se estiver logado, se não estiver vai para o login
   */
  goTo() {
    if (this.isLoggedIn) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate(['/login']);
    }
  }
}
