import { createReducer, on } from '@ngrx/store';
import { ColumnsTableCustom } from '../interfaces/ColumnsTableCustom';
import {
  addColsSelectedsCRT,
  addColsSelectedsMIC,
  clearDataTablePreferences,
} from './table-preferences.actions';

export interface TablePreferencesState {
  colsCRT: ColumnsTableCustom[];
  colsMIC: ColumnsTableCustom[];
}

export const initialTablePreferencesState: TablePreferencesState = {
  colsCRT: [],
  colsMIC: [],
};

export const tablePreferencesStateReducer = createReducer(
  initialTablePreferencesState,

  on(addColsSelectedsCRT, (state, { colsCRT }) => {
    return {
      ...state,
      colsCRT,
    };
  }),

  on(addColsSelectedsMIC, (state, { colsMIC }) => {
    return {
      ...state,
      colsMIC,
    };
  }),

  on(clearDataTablePreferences, () => initialTablePreferencesState)
);
