import { HttpBackend, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppInitService } from '../services/app-init/app-init.service';

export function initializeApp1(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.Init();
  };
}

export function HttpLoaderFactory(http: HttpBackend) {
  return new TranslateHttpLoader(
    new HttpClient(http),
    './assets/i18n/',
    '.json'
  );
}
