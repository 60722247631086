import { Component, OnInit } from '@angular/core';
import { SkeletonModule } from 'primeng/skeleton';

/**
 * * Componente de load para menu
 * @description É um skeleton de carregamento para o menu.
 * @author Dariane Abich
 * @date 24/04/2024
 * @export
 * @class LoadMenuComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-load-menu',
    templateUrl: './load-menu.component.html',
    styleUrls: ['./load-menu.component.scss'],
    standalone: true,
    imports: [SkeletonModule],
})
export class LoadMenuComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
