import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalComponentService {
  constructor() {}

  /**
   * @description Seleciona o select (preferência para o primeiro input do formulário) e adiciona o focus ao abrir o modal. OBS: Não funciona para ng-select, precisa ser inputs normais
   * @param {string} formControlName Nome do formControl do primeiro input válido do formulário
   * @param {ElementRef} elementRef ElementRef injetado do componente
   */
  setFocus(formControlName: string, elementRef: ElementRef) {
    setTimeout(() => {
      this._setFocusActions(formControlName, elementRef);
    }, 900);
  }

  private _setFocusActions(formControlName: string, elementRef: ElementRef) {
    const element = elementRef.nativeElement.querySelector(
      `[formcontrolname='${formControlName}']`
    );
    if (element) {
      element.focus();
    }
  }
}
