import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Message, MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private messages: MessageService = inject(MessageService);
  private translate: TranslateService = inject(TranslateService);

  private timer: any;

  constructor() {}

  registerSuccess() {
    const message: Message = {
      severity: 'success',
      summary: this.translate.instant('messages.success'),
      detail: this.translate.instant('messages.registrationSuccess'),
      life: 5000,
    };

    this.addMessageToService(message);
  }

  updateSuccess() {
    const message: Message = {
      severity: 'success',
      summary: this.translate.instant('messages.success'),
      detail: this.translate.instant('messages.updateSuccess'),
    };

    this.addMessageToService(message);
  }

  duplicateSuccess() {
    const message: Message = {
      severity: 'success',
      summary: this.translate.instant('messages.success'),
      detail: this.translate.instant('messages.registrationDuplicateSuccess'),
      life: 5000,
    };

    this.addMessageToService(message);
  }

  removeRegister() {
    const message: Message = {
      severity: 'info',
      summary: this.translate.instant('messages.removed'),
      detail: this.translate.instant('messages.removedRegister'),
    };

    this.addMessageToService(message);
  }

  messageEnableSuccess() {
    const message: Message = {
      severity: 'info',
      summary: this.translate.instant('common.enabled'),
      detail: this.translate.instant('messages.enabledRegister'),
    };

    this.addMessageToService(message);
  }

  messageDisableSuccess() {
    const message: Message = {
      severity: 'info',
      summary: this.translate.instant('common.disabled'),
      detail: this.translate.instant('messages.disabledRegister'),
    };

    this.addMessageToService(message);
  }

  messageGenerateExcel() {
    const message: Message = {
      severity: 'success',
      summary: this.translate.instant('messages.success'),
      detail: this.translate.instant('messages.downloadExcelSuccess'),
    };

    this.addMessageToService(message);
  }

  messageGeneratePdf() {
    const message: Message = {
      severity: 'success',
      summary: this.translate.instant('messages.success'),
      detail: this.translate.instant('messages.downloadPdfSuccess'),
    };

    this.addMessageToService(message);
  }

  messageGenerateDocument() {
    const message: Message = {
      severity: 'success',
      summary: this.translate.instant('messages.success'),
      detail: this.translate.instant('messages.documentSuccess'),
    };

    this.addMessageToService(message);
  }

  changePasswordSuccess() {
    const message: Message = {
      severity: 'success',
      summary: this.translate.instant('messages.success'),
      detail: this.translate.instant('messages.resetPasswordSuccess'),
    };

    this.addMessageToService(message);
  }

  saveFavorite() {
    const message: Message = {
      severity: 'success',
      summary: this.translate.instant('messages.success'),
      detail: this.translate.instant('messages.screenAddedToFavorites'),
    };

    this.addMessageToService(message);
  }

  deleteFavorite() {
    const message: Message = {
      severity: 'info',
      summary: this.translate.instant('messages.removed'),
      detail: this.translate.instant('messages.screenRemovedToFavorites'),
    };

    this.addMessageToService(message);
  }

  copiedToClipboard(field: string) {
    const message: Message = {
      severity: 'info',
      detail: this.translate.instant('messages.copiedToClipboard', {
        field: this.translate.instant(`${field}`),
      }),
    };

    this.addMessageToService(message);
  }

  menuNotAvailable() {
    const message: Message = {
      severity: 'warn',
      summary: this.translate.instant('messages.notice'),
      detail: this.translate.instant('components.menuNotAvailable'),
    };

    this.addMessageToService(message);
  }

  parameterSuccess() {
    const message: Message = {
      severity: 'success',
      summary: this.translate.instant('messages.success'),
      detail: this.translate.instant('messages.parametersSuccess'),
    };

    this.addMessageToService(message);
  }

  sendEmailSuccess() {
    const message: Message = {
      severity: 'success',
      summary: this.translate.instant('messages.success'),
      detail: this.translate.instant('messages.emailSuccess'),
    };

    this.addMessageToService(message);
  }

  configurationSuccess() {
    const message: Message = {
      severity: 'success',
      summary: this.translate.instant('messages.success'),
      detail: this.translate.instant('messages.configurationSuccess'),
    };

    this.addMessageToService(message);
  }

  operationError() {
    const message: Message = {
      severity: 'error',
      summary: this.translate.instant('messages.error'),
      detail: this.translate.instant(
        'messages.anErrorOccurredPerformingOperation'
      ),
    };

    this.addMessageToService(message);
  }

  /**
   * @description Adiciona uma mensagem ao serviço de mensagens
   * @param message Objeto de mensagem que será mostrada
   */
  addMessageToService(message: Message) {
    this.messages.add(message);
    this._resetTimer();
  }

  /**
   * @description Limpa as mensagens do serviço de mensagens após 1 segundo e/ou reseta o timer caso o mesmo já esteja em contagem
   */
  private _resetTimer() {
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.messages.clear();
    }, 3000);
  }
}
