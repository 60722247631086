import { CompanyListModel } from '../companies/CompanyListModel';
import { FavoritesMenuItemModel } from '../menu/FavoritesMenuItemModel';
import { WebMenuItemsModel } from '../menu/WebMenuItemsModel';

export interface ProfileModel {
  id?: number;
  authorizationServerId?: string;
  name?: string;
  email?: string;
  active?: boolean;
  showWelcomeTour?: boolean;
  language?: string;
  webMenuItems?: WebMenuItemsModel[];
  favoritesMenuItem?: FavoritesMenuItemModel[];
  defaultCompany?: CompanyListModel;
  otherCompanies?: CompanyListModel[];
}

export function ProfileModel(
  id?: number,
  authorizationServerId?: string,
  name?: string,
  email?: string,
  active?: boolean,
  showWelcomeTour?: boolean,
  language?: string,
  webMenuItems?: WebMenuItemsModel[],
  favoritesMenuItem?: FavoritesMenuItemModel[],
  defaultCompany?: CompanyListModel,
  otherCompanies?: CompanyListModel[]
): ProfileModel {
  return {
    id,
    authorizationServerId,
    name,
    email,
    active,
    showWelcomeTour,
    language,
    webMenuItems,
    favoritesMenuItem,
    defaultCompany,
    otherCompanies,
  };
}
