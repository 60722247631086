<div class="div-erro">
  <img
    src="assets/images/errors/finance-pending-error.gif"
    width="400px"
    height="250px"
    alt=""
  />
  <div class="texto">
    <h2 class="heading-2">{{ "messages.blockedAccess" | translate }}</h2>
    <p class="p-default">
      {{
        "messages.yourAccessToTheSystemHasBeenSuspendedDueToFinancial"
          | translate
      }}<br />
      {{
        "messages.toRestoreAccessRegularizeYourSituationSoonPossible"
          | translate
      }}
    </p>

    <p class="p-default">
      {{
        "messages.ifHaveQuestionsRequireFurtherInformationContactFinancialDepartment"
          | translate
      }}
    </p>
  </div>
</div>
