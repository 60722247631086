import { createReducer, on } from '@ngrx/store';
import { ProfileCompanyState } from '../models/company-reducer/ProfileCompanyState';
import {
  addCompaniesDetails,
  addItemOnAllCompaniesAndOthersCompanies,
  removeComapaniesDetails,
  removeItemOnAllCompaniesAndOthersCompanies,
  updateItemOnAllCompaniesAndOthersCompanies,
  updateSelectedCompany,
} from './company.actions';

export const initialState: ProfileCompanyState = {
  defaultCompany: null,
  othersCompanies: [],
  allCompanies: [],
  selectedCompany: null,
};

export const profileCompanyReducer = createReducer(
  initialState,

  on(addCompaniesDetails, (state, { profileCompany: profile }) => {
    return {
      ...state,
      defaultCompany: profile.defaultCompany,
      othersCompanies: profile.othersCompanies,
      allCompanies: profile.allCompanies,
      selectedCompany: profile.selectedCompany,
    };
  }),

  on(updateSelectedCompany, (state, { selectedCompany }) => ({
    ...state,
    selectedCompany,
  })),

  on(addItemOnAllCompaniesAndOthersCompanies, (state, { company }: any) => {
    const updatedOthersCompanies = Array.isArray(state.othersCompanies)
      ? [...state.othersCompanies, company]
      : [company];

    const updatedAllCompanies = Array.isArray(state.allCompanies)
      ? [...state.allCompanies, company]
      : [company];

    return {
      ...state,
      othersCompanies: updatedOthersCompanies,
      allCompanies: updatedAllCompanies,
    };
  }),

  on(updateItemOnAllCompaniesAndOthersCompanies, (state, { company }: any) => {
    const allCompanies = state.allCompanies?.map((companyItem) =>
      companyItem.id === company.id
        ? { ...companyItem, ...company }
        : companyItem
    );

    const othersCompanies = state.othersCompanies?.map((companyItem) =>
      companyItem.id === company.id
        ? { ...companyItem, ...company }
        : companyItem
    );

    const selectedCompany =
      state.selectedCompany?.id === company.id
        ? company
        : state.selectedCompany;

    const defaultCompany =
      state.defaultCompany?.id === company.id ? company : state.defaultCompany;

    return {
      ...state,
      defaultCompany,
      allCompanies,
      othersCompanies,
      selectedCompany,
    };
  }),

  on(removeItemOnAllCompaniesAndOthersCompanies, (state, { company }: any) => {
    const allCompanies = state.allCompanies?.filter(
      (companyItem) => companyItem.id !== company.id
    );

    const othersCompanies = state.othersCompanies?.filter(
      (companyItem) => companyItem.id !== company.id
    );

    return {
      ...state,
      othersCompanies: othersCompanies,
      allCompanies: allCompanies,
    };
  }),

  on(removeComapaniesDetails, (state) => {
    return {
      ...state,
      defaultCompany: null,
      othersCompanies: [],
      allCompanies: [],
      selectedCompany: null,
    };
  })
);
