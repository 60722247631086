import { Routes } from '@angular/router';
import { AccessDeniedComponent } from './shared/common/components/access-denied/access-denied.component';
import { Erro404Component } from './shared/common/components/erro404/erro404.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/pages.routes').then((r) => r.PAGES_ROUTES),
  },
  {
    path: '**',
    component: Erro404Component,
  },
  {
    path: 'pending-payment',
    component: AccessDeniedComponent,
  },
];
