import { CompaniesState } from '../company-reducer/CompaniesState';

export interface CompanyDataState {
  hasDefaultCompany?: boolean;
  hasOthersCompanies?: boolean;
  defaultCompanyName?: string;
  defaultCompanyId?: number | null;
  selectedCompany?: CompaniesState | null;
  otherCompaniesList?: CompaniesState[];
  allCompaniesList?: CompaniesState[];
}

export function CompanyDataState(
  hasDefaultCompany?: boolean,
  hasOthersCompanies?: boolean,
  defaultCompanyName?: string,
  defaultCompanyId?: number | null,
  selectedCompany?: CompaniesState | null,
  otherCompaniesList?: CompaniesState[],
  allCompaniesList?: CompaniesState[]
): CompanyDataState {
  return {
    hasDefaultCompany,
    hasOthersCompanies,
    defaultCompanyName,
    defaultCompanyId,
    selectedCompany,
    otherCompaniesList,
    allCompaniesList,
  };
}
