<nav id="topbar" class="navbar navbar-expand-lg navbar-fixed-top">
  <div class="container-fluid">
    <div class="logo" id="step-3-navbar-logo">
      <a routerLink="/"
        ><img src="assets/images/logos/logo-img.png" alt="logo" id="logo-img"
      /></a>
      <a
        class="navbar-brand"
        routerLink="/"
        pTooltip="{{ 'common.home' | translate }}"
        tooltipPosition="bottom"
        [showDelay]="300"
        >PROPULSOR</a
      >
    </div>

    <app-load-navbar *ngIf="loadingNavbar"></app-load-navbar>

    <div class="profile-controls" *ngIf="!loadingNavbar">
      <div class="flex div-dev-mode" *ngIf="isDevMode">
        <p class="p-dev-mode">
          {{ "common.developmentEnvironment" | translate }}
        </p>
      </div>

      <form [formGroup]="searchMenuForm">
        <div class="search-container" (click)="clickDivSearchMenu()">
          <p-autoComplete
            #searchAllMenus
            id="searchAllMenus"
            formControlName="searchAllMenus"
            field="label"
            class="custom-scroll"
            placeholder="{{ 'components.searchOnAllSystem' | translate }}"
            emptyMessage="{{ 'components.menuNotFound' | translate }}"
            [suggestions]="menusDisponiveis"
            [showEmptyMessage]="showMessage"
            (completeMethod)="searchMenu($event)"
            (onSelect)="clickSearchMenu($event)"
          >
            <ng-template let-menu pTemplate="item">
              <div class="flex search-div">
                <i class="{{ menu.icon }} icone"></i>
                <div>
                  {{ menu.label }}
                </div>
              </div>
            </ng-template>
          </p-autoComplete>

          <i
            *ngIf="!searchAllMenus.loading"
            class="pi pi-search search-icon"
            (click)="searchAllMenus.show()"
          ></i>
        </div>
      </form>

      <div class="btn-profile">
        <button
          id="userName"
          pButton
          type="button"
          label="{{ nameUser }}"
          icon="pi pi-user"
          class="p-button-text secondary"
          pTooltip="{{ 'common.myProfile' | translate }}"
          tooltipPosition="bottom"
          [showDelay]="300"
          (click)="menuUsuario1.toggle($event)"
        ></button>

        <button
          id="userNameSmall"
          pButton
          type="button"
          label=""
          icon="pi pi-user"
          class="p-button-text secondary"
          pTooltip="{{ nameUser }}"
          tooltipPosition="bottom"
          [showDelay]="300"
          (click)="menuUsuario2.toggle($event)"
        ></button>
        <p-menu #menuUsuario1 [popup]="true" [model]="userItems"></p-menu>
        <p-menu #menuUsuario2 [popup]="true" [model]="userItems"></p-menu>
      </div>

      <div class="btn-empresas">
        <button
          id="companyName1"
          pButton
          type="button"
          label="{{ 'common.registerCompany' | translate }}"
          icon="pi pi-building"
          class="p-button-text secondary"
          (click)="goToNewCompany()"
          *ngIf="!companyData.hasDefaultCompany"
        >
          <ng-template ng-label-tmp let-item="item">
            <div class="flex custom-div-company">
              <i class="pi pi-building custom-company-icon"></i>
              <div>{{ item.name }}</div>
            </div>
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            <div class="flex custom-div-company">
              <i class="pi pi-building custom-company-icon"></i>
              <div>{{ item.name }}</div>
            </div>
          </ng-template>
        </button>

        <button
          id="companyName2"
          pButton
          type="button"
          [label]="companyData.defaultCompanyName"
          icon="pi pi-building"
          class="p-button-text secondary no-effect"
          pTooltip="{{ companyData.defaultCompanyName }}"
          tooltipPosition="bottom"
          [showDelay]="300"
          *ngIf="
            companyData.hasDefaultCompany &&
            companyData.defaultCompanyName &&
            !companyData.hasOthersCompanies
          "
        >
          <ng-template ng-label-tmp let-item="item">
            <div class="flex custom-div-company">
              <i class="pi pi-building custom-company-icon"></i>
              <div>{{ item.name }}</div>
            </div>
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            <div class="flex custom-div-company">
              <i class="pi pi-building custom-company-icon"></i>
              <div>{{ item.name }}</div>
            </div>
          </ng-template>
        </button>

        <ng-select
          #menuEmpresas
          *ngIf="
            companyData.hasDefaultCompany &&
            companyData.hasOthersCompanies &&
            companyData.otherCompaniesList &&
            companyData.selectedCompany
          "
          [items]="companyData.allCompaniesList!"
          [(ngModel)]="companyData.selectedCompany"
          [clearable]="false"
          [searchable]="false"
          [selectOnTab]="true"
          class="custom customizedSelect"
          bindLabel="name"
          notFoundText="{{ 'messages.noResultsFound' | translate }}"
          loadingText="{{ 'common.wait' | translate }}"
          (change)="changeSelectedCompany($event)"
        >
          <ng-template ng-label-tmp let-item="item">
            <div
              class="flex custom-div-company company-selecionado"
              pTooltip="{{ item.name }}"
              tooltipPosition="bottom"
              [showDelay]="300"
            >
              <i class="pi pi-building custom-company-icon"></i>
              <div>{{ item.name }}</div>
            </div>
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            <div class="flex custom-div-company">
              <i class="pi pi-building custom-company-icon"></i>
              <div>{{ item.name }}</div>
            </div>
          </ng-template>
        </ng-select>
      </div>

      <div class="btn-notificacoes">
        <button
          pButton
          type="button"
          label=""
          icon="pi pi-bell"
          class="p-button-text secondary"
          pTooltip="{{ 'common.notifications' | translate }}"
          tooltipPosition="bottom"
          [showDelay]="300"
          (click)="menuNotificacoes.toggle($event)"
        ></button>
        <p-menu
          #menuNotificacoes
          [popup]="true"
          [model]="notificationsItems"
        ></p-menu>
      </div>

      <div class="btn-config">
        <button
          pButton
          type="button"
          label=""
          icon="pi pi-cog"
          class="p-button-text secondary"
          pTooltip="{{ 'common.settings' | translate }}"
          tooltipPosition="bottom"
          [showDelay]="300"
          (click)="menuConfig.toggle($event)"
        ></button>
        <p-menu #menuConfig [popup]="true" [model]="settingsItems"></p-menu>
      </div>

      <button
        pButton
        type="button"
        label=""
        icon="pi pi-power-off"
        class="p-button-text secondary"
        pTooltip="{{ 'common.goOut' | translate }}"
        tooltipPosition="bottom"
        [showDelay]="300"
        (click)="confirmLogout($event)"
      ></button>
      <p-confirmPopup key="myConfirmationDialog"></p-confirmPopup>
    </div>
  </div>
</nav>
