import { AsyncPipe, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ShepherdService } from 'angular-shepherd';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DialogService } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import { filter, Subscription, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  getLocalStorageItemNoDecrypt,
  removeLocalStorageItem,
  setLocalStorageItemNoEncrypt,
} from './core/common/helpers/LocalStorageUtils';
import { AuthService } from './core/common/services/auth/auth.service';
import { LanguageService } from './core/common/services/language/language.service';
import { VersionCheckService } from './core/common/services/update-service/udpate.service';
import { updateShowWelcomeTour } from './core/common/store/menu.actions';
import { IMenuState } from './core/common/store/menu.state';
import { ProfileFacade } from './pages/settings/general-settings/management-registers/my-profile/facade/ProfileFacade';
import { AppModalUpdatePropulsorComponent } from './shared/common/components/modals/app-modal-update-propulsor/app-modal-update-propulsor.component';
import { AppMenuComponent } from './shared/common/components/template/app-menu/app-menu.component';
import { AppNavbarComponent } from './shared/common/components/template/app-navbar/app-navbar.component';

const userLanguageKey = 'user_language_propulsor';
const langPtbr = 'pt-BR';
const langEsar = 'es-AR';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ProfileFacade],
  standalone: true,
  imports: [
    NgIf,
    RouterOutlet,
    DialogModule,
    ButtonModule,
    AsyncPipe,
    TranslateModule,
    AppNavbarComponent,
    AppMenuComponent,
    ToastModule,
  ],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  private router: Router = inject(Router);
  private shepherdService: ShepherdService = inject(ShepherdService);
  public authService: AuthService = inject(AuthService);
  private profileFacade: ProfileFacade = inject(ProfileFacade);
  private translate: TranslateService = inject(TranslateService);
  private langService: LanguageService = inject(LanguageService);
  private store: Store<{ menu: IMenuState }> = inject(
    Store<{ menu: IMenuState }>
  );
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  private versionCheckService: VersionCheckService =
    inject(VersionCheckService);

  readonly TABLEPROPVERSION_KEY = 'table_preferences_version_propulsor';
  readonly COLUMNCRT_KEY = 'col_CRT_propulsor';
  readonly COLUMNMIC_KEY = 'col_MIC_propulsor';
  readonly SELECTCOMPNAV_KEY = 'select_Company_nav_propulsor';

  title = 'propulsor-ui';
  stateMenu: boolean = false;
  onLogin: boolean = false;
  initialTour: boolean = false;
  finishTour: boolean = false;
  stepsTour: any[] = [];
  userAgreedTour: boolean = true;
  finishingTour: boolean = false;
  dbsystemMail: string = 'atendimento@dbsystem.com.br';
  dbsystemNumber: string = '+55 55 3431 9015';

  private subscription?: Subscription;
  dialogService: DialogService = inject(DialogService);

  constructor() {
    this.onLogin = false;

    this.subscription = this.profileFacade.isHome$.subscribe(
      (isHome: boolean) => {
        if (isHome) {
          this.store
            .select((state) => state.menu)
            .subscribe({
              next: (menu) => {
                this.verifyLocalstorage(menu.showWelcomeTour);
              },
            });
        }
      }
    );

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.authService.isAuthenticated().then((isAuth) => {
          if (event.url === '/login' && isAuth) {
            this.router.navigate(['/']);
            setTimeout(() => {
              location.reload();
            }, 100);
          }

          // se estava logado e perdeu a autenticação, não mostrar mais o tour
          if (!isAuth) {
            this.shepherdService.modal ? this.shepherdService?.cancel() : '';
            this.userAgreedTour = false;
            this.initialTour = false;
            this.finishTour = false;
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.router.events.subscribe().unsubscribe();
  }

  ngOnInit() {
    sessionStorage.clear();

    localStorage.removeItem('welcomeTourOpen');

    this.langService.setLangFromLocalStorage();

    let pathName = window.location.pathname;
    if (pathName !== '/login') {
      this.initPermissions();
    }

    this.langService.updatePrimeNgTranslate();
    this.verifyVersionTablePreferences();

    this.versionCheckService.updateAvailable$.subscribe((isUpdateAvailable) => {
      if (pathName !== '/login') {
        if (isUpdateAvailable) {
          this.openDialog();
        }
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.userAgreedTour) {
        this.initialTour = true;
      }
    }, 0);

    this.cdr.detectChanges();
  }

  /**
   * @description Verifica as versões do sistema e do localstorage para as preferencias de tabelas, se forem diferentes, limpa o localstorage e salva a versão atual do sistema
   */
  verifyVersionTablePreferences() {
    const versTablePreferencesLocalStorage = getLocalStorageItemNoDecrypt(
      this.TABLEPROPVERSION_KEY
    );

    if (!versTablePreferencesLocalStorage) {
      this._cleanLocalStoragePreferencesAndSaveVersion();
    } else {
      if (
        parseInt(versTablePreferencesLocalStorage) !==
        environment.tablePreferencesVersion
      ) {
        this._cleanLocalStoragePreferencesAndSaveVersion();
      }
    }
  }

  /**
   * @description Limpa as preferências de tabela do localstorage e salva a versão atual do sistema
   */
  private _cleanLocalStoragePreferencesAndSaveVersion() {
    removeLocalStorageItem(this.COLUMNCRT_KEY);
    removeLocalStorageItem(this.COLUMNMIC_KEY);
    removeLocalStorageItem(this.SELECTCOMPNAV_KEY);
    setLocalStorageItemNoEncrypt(
      this.TABLEPROPVERSION_KEY,
      environment.tablePreferencesVersion
    );
  }

  /**
   * Buscar permissões do usuário logado
   */
  async initPermissions() {
    await this.profileFacade.getPermission();
  }

  /**
   * Verifica o valor de 'welcomeTourOpen' no localstorage. Se não for 'andamento' ou nulo, irá verificar se tem usuário logado e mostrar o tour.
   * @param {any} user usuário
   */
  verifyLocalstorage(user: any) {
    if (
      localStorage.getItem('welcomeTourOpen') === null ||
      localStorage.getItem('welcomeTourOpen') !== 'andamento'
    ) {
      if (user === 'true' || user === true) {
        this.initialTour = true;
        localStorage.setItem('welcomeTourOpen', 'andamento');
      }
    }
  }

  /**
   * Aceitar a visualização/inicialização do tour
   */
  public callTourUser() {
    this.userAgreedTour = true;
    this.initialTour = true;
  }

  /**
   * Verificar se o menu está aberto ou fechado
   * @param {boolean} state valor do estado do menu
   */
  getStateMenu(state: boolean) {
    this.stateMenu = state;

    if (state) {
      document.getElementById('div-body')!.style.marginLeft = '180px';
    } else {
      document.getElementById('div-body')!.style.marginLeft = '0';
    }
  }

  /**
   * Voltar para a tela inicial
   */
  backToHome() {
    this.router.navigate(['/']);
    this.shepherdService.back();
  }

  /**
   * Ir para a tela inicial
   */
  goToHome() {
    this.router.navigate(['/']);
    this.shepherdService.next();
  }

  /**
   * Pular o tour e finalizar o fluxo do modal
   */
  skipTour() {
    this.shepherdService?.complete();
    this.userAgreedTour = false;
    this.initialTour = false;
    this.finishTour = true;
  }

  /**
   * Pular o tour e fechar o modal
   */
  skipTourModal() {
    this.userAgreedTour = false;
    this.initialTour = false;
    this.finishTour = true;
  }

  /**
   * Finalizar o tour
   */
  finishTourModal() {
    this.shepherdService.complete();
    this.userAgreedTour = false;
    this.finishTour = true;
  }

  /**
   * Configurar o Shepherd (passos do tour)
   */
  configShephard() {
    this.initialTour = false;

    this.router.navigate(['/']);

    const step1Submenu = {
      id: 'step-1-submenu',
      attachTo: {
        element: '#step-1-submenu',
        on: 'right',
      },
      buttons: [
        {
          classes: 'p-button p-button-text secondary',

          text: this.translate.instant('appComponent.skipTutorial'),
          action: () => this.skipTour(),
        },
        {
          classes: 'p-button p-button-raised primary',

          text: this.translate.instant('appComponent.next'),
          action: () => this.goToHome(),
        },
      ],
      title: `<h6 class="heading-6">${this.translate.instant(
        'appComponent.sidebarMenu'
      )}</h6>`,
      text: [
        `<p class="p-default">` +
          this.translate.instant('appComponent.step1Submenu1st') +
          `</p> <p class="p-default">` +
          this.translate.instant('appComponent.step1Submenu2nd') +
          `</p>`,
      ],
    };

    const step2Navbar = {
      id: 'step-2-navbar',
      attachTo: {
        element: '.step-2-navbar',
        on: 'bottom',
      },
      buttons: [
        {
          classes: 'p-button p-button-text secondary',
          text: this.translate.instant('appComponent.skipTutorial'),
          action: () => this.skipTour(),
        },
        {
          classes: 'p-button p-button-raised primary',
          text: this.translate.instant('appComponent.previous'),
          action: () => this.backToHome(),
        },
        {
          classes: 'p-button p-button-raised primary',
          text: this.translate.instant('appComponent.next'),
          action: () => this.goToHome(),
        },
      ],
      title: `<h6 class="heading-6">${this.translate.instant(
        'appComponent.topPanel'
      )}</h6>`,
      text: [
        `<p class="p-default">` +
          this.translate.instant('appComponent.step2Navbar1st') +
          `<li class="p-default">` +
          this.translate.instant('appComponent.step2Navbar2nd') +
          `</li> <li class="p-default">` +
          this.translate.instant('appComponent.step2Navbar3rd') +
          `</li> <li class="p-default">` +
          this.translate.instant('appComponent.step2Navbar4th') +
          `</li> <li class="p-default">` +
          this.translate.instant('appComponent.step2Navbar5th') +
          `</li></p>`,
      ],
    };

    const step3NavbarLogo = {
      id: 'step-3-navbar-logo',
      attachTo: {
        element: '#step-3-navbar-logo',
        on: 'bottom',
      },
      buttons: [
        {
          classes: 'p-button p-button-text secondary',
          text: this.translate.instant('appComponent.skipTutorial'),
          action: () => this.skipTour(),
        },
        {
          classes: 'p-button p-button-raised primary',
          text: this.translate.instant('appComponent.previous'),
          action: () => this.backToHome(),
        },
        {
          classes: 'p-button p-button-raised primary',
          text: this.translate.instant('appComponent.next'),
          action: () => this.goToHome(),
        },
      ],
      title: `<h6 class="heading-6">${this.translate.instant(
        'appComponent.topPanel'
      )}</h6>`,
      text: [
        `<p class="p-default">` +
          this.translate.instant('appComponent.step3NavbarLogo') +
          `</p>`,
      ],
    };

    const step4Help = {
      id: 'step-4-help',
      attachTo: {
        element: '#step-4-help',
        on: 'bottom',
      },
      buttons: [
        {
          classes: 'p-button p-button-text secondary',
          text: this.translate.instant('appComponent.skipTutorial'),
          action: () => this.skipTour(),
        },
        {
          classes: 'p-button p-button-raised primary',
          text: this.translate.instant('appComponent.previous'),
          action: () => this.backToHome(),
        },
        {
          classes: 'p-button p-button-raised primary',
          text: this.translate.instant('appComponent.next'),
          action: () => this.goToHome(),
        },
      ],
      title: `<h6 class="heading-6">${this.translate.instant(
        'common.help'
      )}</h6>`,
      text: [
        `<p class="p-default">` +
          this.translate.instant('appComponent.step4Help') +
          `</p>`,
      ],
    };

    const step5Favorite = {
      id: 'step-5-favorite',
      attachTo: {
        element: '#step-5-favorite',
        on: 'bottom',
      },
      buttons: [
        {
          classes: 'p-button p-button-text secondary',
          text: this.translate.instant('appComponent.skipTutorial'),
          action: () => this.skipTour(),
        },
        {
          classes: 'p-button p-button-raised primary',
          text: this.translate.instant('appComponent.previous'),
          action: () => this.backToHome(),
        },
        {
          classes: 'p-button p-button-raised primary',
          text: this.translate.instant('appComponent.next'),
          action: () => this.goToHome(),
        },
      ],
      title: `<h6 class="heading-6">${this.translate.instant(
        'common.favorite'
      )}</h6>`,
      text: [
        `<p class="p-default">` +
          this.translate.instant('appComponent.step5Favorite') +
          `<i class="pi pi-star-fill"></i> </p>`,
      ],
    };

    const step6AcessosRapidos = {
      id: 'step-6-acessos-rapidos',
      attachTo: {
        element: '#step-6-acessos-rapidos',
        on: 'top',
      },
      buttons: [
        {
          classes: 'p-button p-button-text secondary',
          text: this.translate.instant('appComponent.skipTutorial'),
          action: () => this.skipTour(),
        },
        {
          classes: 'p-button p-button-raised primary',
          text: this.translate.instant('appComponent.previous'),
          action: () => this.backToHome(),
        },
        {
          classes: 'p-button p-button-raised primary',
          text: this.translate.instant('appComponent.next'),

          action: () => this.finishTourModal(),
        },
      ],
      title: `<h6 class="heading-6">${this.translate.instant(
        'common.quickShortcuts'
      )}</h6>`,
      text: [
        `<p class="p-default">` +
          this.translate.instant('appComponent.step6AcessosRapidos1st') +
          `</p><p class="p-default">` +
          this.translate.instant('appComponent.step6AcessosRapidos2nd') +
          `</p>`,
      ],
      beforeShowPromise: function () {
        return new Promise<void>(function (resolve) {
          setTimeout(function () {
            const element = document.getElementById('step-6-acessos-rapidos');
            element?.scrollIntoView({ behavior: 'smooth' });
            resolve();
          }, 400);
        });
      },
    };

    this.stepsTour = [
      step1Submenu,
      step2Navbar,
      step3NavbarLogo,
      step4Help,
      step5Favorite,
      step6AcessosRapidos,
    ];

    this.shepherdService.defaultStepOptions = {
      cancelIcon: {
        enabled: false,
      },
      highlightClass: 'highlight',
      scrollTo: false,
      beforeShowPromise: function () {
        return new Promise<void>(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve();
          }, 400);
        });
      },
    };
    this.shepherdService.modal = true;
    this.shepherdService.confirmCancel = false;
    this.shepherdService.addSteps(this.stepsTour);
    this.shepherdService.start();
  }

  /**
   * Salvar a configuração final do tour
   */
  completeTour() {
    this.finishingTour = true;

    this.profileFacade.saveWelcomeTour().subscribe({
      next: () => {
        this.finishTour = false;
        localStorage.removeItem('welcomeTourOpen');
        this.store.dispatch(updateShowWelcomeTour({ showWelcomeTour: false }));
        this.finishingTour = false;
        this.router.navigate(['/']);
      },

      error: (error) => {},
    });
  }

  openDialog() {
    const ref = this.dialogService.open(AppModalUpdatePropulsorComponent, {
      header: this.translate.instant('common.propulsorUpgrade'),
      width: '512px',
      height: 'auto',
      closable: false,
      data: {
        version: environment.appVersion,
      },
    });

    ref.onClose.pipe(take(1)).subscribe(() => {
      localStorage.setItem('appVersion', environment.appVersion);
      window.location.reload();
    });
  }
}
