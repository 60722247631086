import { ChangePasswordModel } from 'src/app/core/common/models/password/ChangePasswordModel';
import * as model from 'src/app/core/common/models/profile/Profile';

export class ProfileModelMapper {
  constructor() {}

  mapToPutModel(data: any): model.ProfilePutModel {
    let profile = model.ProfilePutModel(data.name, data.language);

    return profile;
  }

  mapToPutPasswordModel(data: any): ChangePasswordModel {
    let password = ChangePasswordModel(data.currentPassword, data.newPassword);
    return password;
  }
}
